import { render, staticRenderFns } from "./goods.vue?vue&type=template&id=79fd933c&scoped=true&"
import script from "./goods.vue?vue&type=script&lang=js&"
export * from "./goods.vue?vue&type=script&lang=js&"
import style0 from "./goods.vue?vue&type=style&index=0&id=79fd933c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79fd933c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\www\\iot_vehicle\\iot_frontend\\iot_admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('79fd933c')) {
      api.createRecord('79fd933c', component.options)
    } else {
      api.reload('79fd933c', component.options)
    }
    module.hot.accept("./goods.vue?vue&type=template&id=79fd933c&scoped=true&", function () {
      api.rerender('79fd933c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/popularize/goods.vue"
export default component.exports