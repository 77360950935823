"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

var _topBanner = _interopRequireDefault(require("./child/topBanner"));

var _nineGrid = _interopRequireDefault(require("./child/nineGrid"));

var _capsuleBanner = _interopRequireDefault(require("./child/capsuleBanner"));

var _thirdHot = _interopRequireDefault(require("./child/thirdHot"));

var _bannerCol = _interopRequireDefault(require("./child/bannerCol"));

var _twoColGoods = _interopRequireDefault(require("./child/twoColGoods"));

var _secBanner = _interopRequireDefault(require("./child/secBanner"));

var _twoActivity = _interopRequireDefault(require("./child/twoActivity"));

var _category = _interopRequireDefault(require("./child/category"));

var _validate = require("@/utils/validate");

var _xcx = require("@/api/xcx");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    topBanner: _topBanner.default,
    capsuleBanner: _capsuleBanner.default,
    nineGrid: _nineGrid.default,
    thirdHot: _thirdHot.default,
    bannerCol: _bannerCol.default,
    twoColGoods: _twoColGoods.default,
    secBanner: _secBanner.default,
    twoActivity: _twoActivity.default,
    category: _category.default
  },
  data: function data() {
    return {
      activeNum: 1,
      sectionList: [{
        title: "首页轮播图片",
        show: true,
        imgName: '1.jpg'
      }, {
        title: "九宫格",
        show: true,
        imgName: '2.jpg'
      }, {
        title: "胶囊轮播图",
        show: true,
        imgName: '3.jpg'
      }, {
        title: "三栏式热门",
        show: true,
        imgName: '4.jpg'
      }, {
        title: "轮播图+二栏式",
        show: true,
        imgName: '5.jpg'
      }, {
        title: "二栏式*2商品",
        show: true,
        imgName: '6.jpg'
      }, {
        title: "次级轮播图",
        show: true,
        imgName: '7.jpg'
      }, {
        title: "二栏式活动",
        show: true,
        imgName: '8.jpg'
      }, {
        title: "品类商品推荐",
        show: true,
        imgName: '9.jpg'
      }]
    };
  },
  watch: {
    activeNum: function activeNum(val) {
      this.getParams(val);
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      (0, _xcx.previewIndex)().then(function (res) {
        var showTypes = res.map(function (k) {
          return k.type;
        });

        _this.sectionList.forEach(function (item, index) {
          var num = index + 1;

          if (showTypes.indexOf(num) === -1) {
            item.show = false;
          }
        });

        _this.getParams(1);
      });
    },
    preview: function preview() {
      this.$router.push({
        name: "xcxPosPreview"
      });
    },
    release: function release() {
      (0, _xcx.releaseOnline)().then(function (res) {});
    },
    getParams: function getParams(type) {
      var _this2 = this;

      (0, _xcx.getParamsByType)(type).then(function (res) {
        if (res) {
          _this2.sectionList[_this2.activeNum - 1].show = res.status == 2;
          var data = JSON.parse(res.params);

          _this2.$nextTick(function () {
            type == 1 && (_this2.$refs.topBanner.bannerList = (0, _toConsumableArray2.default)(data));
            type == 2 && (_this2.$refs.nineGrid.iconList = (0, _toConsumableArray2.default)(data));
            type == 3 && (_this2.$refs.capsuleBanner.banner = data[0]);
            type == 4 && (_this2.$refs.thirdHot.configData = data.main, _this2.$refs.thirdHot.$refs.colForm.goodsList = data.goods);

            if (type == 5) {
              _this2.$refs.bannerCol.carousel = data.carousel;
              _this2.$refs.bannerCol.configData = [data.twoBar.one.main, data.twoBar.two.main];
              _this2.$refs.bannerCol.$refs.colForm1.goods = data.twoBar.one.goods;
              _this2.$refs.bannerCol.$refs.colForm2.goods = data.twoBar.two.goods;
            }

            if (type == 6) {
              _this2.$refs.twoColGoods.configData = [data.one.main, data.two.main];
              _this2.$refs.twoColGoods.$refs.colForm1.goodsList = data.one.goods;
              _this2.$refs.twoColGoods.$refs.colForm2.goodsList = data.two.goods;
            }

            type == 7 && (_this2.$refs.secBanner.bannerList = (0, _toConsumableArray2.default)(data));
            type == 8 && (_this2.$refs.twoActivity.imgList = (0, _toConsumableArray2.default)(data));
            type == 9 && (_this2.$refs.category.dataList = (0, _toConsumableArray2.default)(data));
          });
        }
      });
    },
    // 保存顶部导航栏
    saveTopBanner: function saveTopBanner() {
      var _this3 = this;

      var list = (0, _toConsumableArray2.default)(this.$refs.topBanner.bannerList);

      if (this.validateBannerList(list)) {
        (0, _xcx.saveParams)({
          type: 1,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(list)
        }).then(function (res) {
          _this3.getParams(1);
        });
      }
    },
    // 保存胶囊轮播图
    saveCapsuleBanner: function saveCapsuleBanner() {
      var _this4 = this;

      var list = [this.$refs.capsuleBanner.banner];

      if (this.validateBannerList(list)) {
        (0, _xcx.saveParams)({
          type: 3,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(list)
        }).then(function (res) {
          _this4.getParams(3);
        });
      }
    },
    validateIcon: function validateIcon(list) {
      for (var index in list) {
        var num = parseInt(index) + 1;

        if (!list[index].img) {
          this.$message({
            message: "\u56FE\u6807".concat(num, "\u7684\u56FE\u7247\u4E0D\u80FD\u4E3A\u7A7A"),
            type: "error"
          });
          return false;
        }

        if (!list[index].title) {
          this.$message({
            message: "\u56FE\u6807".concat(num, "\u7684\u540D\u79F0\u4E0D\u80FD\u4E3A\u7A7A"),
            type: "error"
          });
          return false;
        }

        if (!list[index].jumpUrl) {
          this.$message({
            message: "\u56FE\u6807".concat(num, "\u7684\u94FE\u63A5\u4E0D\u80FD\u4E3A\u7A7A"),
            type: "error"
          });
          return false;
        } // if (!checkUrl(list[index].jumpUrl)) {
        //   this.$message({
        //     message: `图标${num}的链接格式不正确`,
        //     type: "error"
        //   });
        //   return false;
        // }

      }

      return true;
    },
    // 保存九宫格
    saveNineGrid: function saveNineGrid() {
      var _this5 = this;

      var list = (0, _toConsumableArray2.default)(this.$refs.nineGrid.iconList);

      if (this.validateIcon(list)) {
        (0, _xcx.saveParams)({
          type: 2,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(list)
        }).then(function (res) {
          _this5.getParams(2);
        });
      }
    },
    // 校验三栏式热门
    validateThirdHot: function validateThirdHot(_ref) {
      var main = _ref.main,
          goods = _ref.goods;
      var backgroundImg = main.backgroundImg,
          title = main.title,
          subtitle = main.subtitle,
          jumpUrl = main.jumpUrl;

      if (!backgroundImg) {
        this.$message({
          message: "请上传背景图",
          type: "warning"
        });
        return false;
      }

      if (!title) {
        this.$message({
          message: "主标题不能为空！",
          type: "error"
        });
        return false;
      }

      if (!subtitle) {
        this.$message({
          message: "副标题不能为空！",
          type: "error"
        });
        return false;
      }

      if (!jumpUrl) {
        this.$message({
          message: "链接不能为空！",
          type: "error"
        });
        return false;
      } // if (!checkUrl(jumpUrl)) {
      //   this.$message({
      //     message: "链接格式不正确",
      //     type: "error"
      //   });
      //   return false;
      // }


      if (goods.length < 3) {
        this.$message({
          message: "添加商品个数不能少于3个！",
          type: "error"
        });
        return false;
      }

      return true;
    },
    // 保存三栏式热门
    saveThirdHot: function saveThirdHot() {
      var _this6 = this;

      var params = {
        main: this.$refs.thirdHot.configData,
        goods: this.$refs.thirdHot.$refs.colForm.goodsList
      };

      if (this.validateThirdHot(params)) {
        (0, _xcx.saveParams)({
          type: 4,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(params)
        }).then(function (res) {
          _this6.getParams(4);
        });
      }
    },
    // 校验轮播图+二栏式
    validateBannerCol: function validateBannerCol(_ref2) {
      var carousel = _ref2.carousel,
          twoBar = _ref2.twoBar;

      if (carousel.length === 0) {
        this.$message({
          message: "请添加轮播图",
          type: "warning"
        });
        return false;
      }

      for (var index = 0; index < carousel.length; index++) {
        var num = index + 1;

        if (!carousel[index].img) {
          this.$message({
            message: "请添加轮播图" + num + "的图片",
            type: "warning"
          });
          return false;
        }

        if (!carousel[index].jumpUrl) {
          this.$message({
            message: "请添加轮播图" + num + "的链接",
            type: "warning"
          });
          return false;
        }
      }

      if (!twoBar.one.main.backgroundImg) {
        this.$message({
          message: "请添加二栏式1的背景图",
          type: "warning"
        });
        return false;
      }

      if (!twoBar.one.main.title) {
        this.$message({
          message: "请输入二栏式1的主标题",
          type: "warning"
        });
        return false;
      }

      if (!twoBar.one.main.subtitle) {
        this.$message({
          message: "请输入二栏式1的副标题",
          type: "warning"
        });
        return false;
      }

      if (!twoBar.one.main.jumpUrl) {
        this.$message({
          message: "请输入二栏式1的链接",
          type: "warning"
        });
        return false;
      } // if (!checkUrl(twoBar.one.main.jumpUrl)) {
      //   this.$message({
      //     message: "二栏式1的链接格式不正确",
      //     type: "error"
      //   });
      //   return false;
      // }


      var _iterator = (0, _createForOfIteratorHelper2.default)(twoBar.one.goods),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (!item.imgurl) {
            this.$message({
              message: "请添加二栏式1的图片",
              type: "warning"
            });
            return false;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      if (!twoBar.two.main.backgroundImg) {
        this.$message({
          message: "请添加二栏式2的背景图",
          type: "warning"
        });
        return false;
      }

      if (!twoBar.two.main.title) {
        this.$message({
          message: "请输入二栏式2的主标题",
          type: "warning"
        });
        return false;
      }

      if (!twoBar.two.main.subtitle) {
        this.$message({
          message: "请输入二栏式2的副标题",
          type: "warning"
        });
        return false;
      }

      if (!twoBar.two.main.jumpUrl) {
        this.$message({
          message: "请输入二栏式2的链接",
          type: "warning"
        });
        return false;
      } // if (!checkUrl(twoBar.two.main.jumpUrl)) {
      //   this.$message({
      //     message: "二栏式2的链接格式不正确",
      //     type: "error"
      //   });
      //   return false;
      // }


      var _iterator2 = (0, _createForOfIteratorHelper2.default)(twoBar.two.goods),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var _item = _step2.value;

          if (!_item.imgurl) {
            this.$message({
              message: "请添加二栏式2的图片",
              type: "warning"
            });
            return false;
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }

      return true;
    },
    // 保存轮播图+二栏式
    saveBannerCol: function saveBannerCol() {
      var _this7 = this;

      var _this$$refs$bannerCol = this.$refs.bannerCol,
          carousel = _this$$refs$bannerCol.carousel,
          configData = _this$$refs$bannerCol.configData;
      var goods1 = this.$refs.bannerCol.$refs.colForm1.goods;
      var goods2 = this.$refs.bannerCol.$refs.colForm2.goods;
      var params = {
        carousel: carousel,
        twoBar: {
          one: {
            main: configData[0],
            goods: goods1
          },
          two: {
            main: configData[1],
            goods: goods2
          }
        }
      };

      if (this.validateBannerCol(params)) {
        (0, _xcx.saveParams)({
          type: 5,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(params)
        }).then(function (res) {
          _this7.getParams(5);
        });
      }
    },
    // 校验二栏式*2商品
    validateTwoColGoods: function validateTwoColGoods(_ref3) {
      var one = _ref3.one,
          two = _ref3.two;

      if (!one.main.backgroundImg) {
        this.$message({
          message: "请添加二栏式1的背景图",
          type: "warning"
        });
        return false;
      }

      if (!one.main.title) {
        this.$message({
          message: "请输入二栏式1的主标题",
          type: "warning"
        });
        return false;
      }

      if (!one.main.subtitle) {
        this.$message({
          message: "请输入二栏式1的副标题",
          type: "warning"
        });
        return false;
      }

      if (!one.main.jumpUrl) {
        this.$message({
          message: "请输入二栏式1的链接",
          type: "warning"
        });
        return false;
      } // if (!checkUrl(one.main.jumpUrl)) {
      //   this.$message({
      //     message: "二栏式1的链接格式不正确",
      //     type: "error"
      //   });
      //   return false;
      // }


      if (one.goods.length < 2) {
        this.$message({
          message: "请添加二栏式1的商品",
          type: "warning"
        });
        return false;
      }

      if (!two.main.backgroundImg) {
        this.$message({
          message: "请添加二栏式2的背景图",
          type: "warning"
        });
        return false;
      }

      if (!two.main.title) {
        this.$message({
          message: "请输入二栏式2的主标题",
          type: "warning"
        });
        return false;
      }

      if (!two.main.subtitle) {
        this.$message({
          message: "请输入二栏式2的副标题",
          type: "warning"
        });
        return false;
      }

      if (!two.main.jumpUrl) {
        this.$message({
          message: "请输入二栏式2的链接",
          type: "warning"
        });
        return false;
      } // if (!checkUrl(two.main.jumpUrl)) {
      //   this.$message({
      //     message: "二栏式2的链接格式不正确",
      //     type: "error"
      //   });
      //   return false;
      // }


      if (two.goods.length < 2) {
        this.$message({
          message: "请添加二栏式2的商品",
          type: "warning"
        });
        return false;
      }

      return true;
    },
    // 保存二栏式*2商品
    saveTwoColGoods: function saveTwoColGoods() {
      var _this8 = this;

      var configData = this.$refs.twoColGoods.configData;
      var goods1 = this.$refs.twoColGoods.$refs.colForm1.goodsList;
      var goods2 = this.$refs.twoColGoods.$refs.colForm2.goodsList;
      var params = {
        one: {
          main: configData[0],
          goods: goods1
        },
        two: {
          main: configData[1],
          goods: goods2
        }
      };

      if (this.validateTwoColGoods(params)) {
        (0, _xcx.saveParams)({
          type: 6,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(params)
        }).then(function (res) {
          _this8.getParams(6);
        });
      }
    },
    // 校验轮播图列表
    validateBannerList: function validateBannerList(list) {
      if (list.length === 0) {
        this.$message({
          message: "请添加轮播图",
          type: "warning"
        });
        return false;
      }

      for (var index = 0; index < list.length; index++) {
        var num = index + 1;

        if (!list[index].img) {
          this.$message({
            message: "请添加轮播图" + num + "的图片",
            type: "warning"
          });
          return false;
        }

        if (!list[index].jumpUrl) {
          this.$message({
            message: "请添加轮播图" + num + "的链接",
            type: "warning"
          });
          return false;
        }
      }

      return true;
    },
    // 保存次级轮播图
    saveSecBanner: function saveSecBanner() {
      var _this9 = this;

      var list = (0, _toConsumableArray2.default)(this.$refs.secBanner.bannerList);

      if (this.validateBannerList(list)) {
        (0, _xcx.saveParams)({
          type: 7,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(list)
        }).then(function (res) {
          _this9.getParams(7);
        });
      }
    },
    // 保存二栏式活动
    saveTwoActivity: function saveTwoActivity() {
      var _this10 = this;

      var imgList = this.$refs.twoActivity.imgList;

      for (var index = 0; index < imgList.length; index++) {
        var num = index + 1;

        if (!imgList[index].img) {
          this.$message({
            message: "请添加图片" + num + "的图片",
            type: "warning"
          });
          return false;
        }

        if (!imgList[index].jumpUrl) {
          this.$message({
            message: "请添加图片" + num + "的链接",
            type: "warning"
          });
          return false;
        } // if (!checkUrl(imgList[index].jumpUrl)) {
        //   this.$message({
        //     message: `图片${num}的链接格式不正确`,
        //     type: "error"
        //   });
        //   return false;
        // }

      }

      (0, _xcx.saveParams)({
        type: 8,
        status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
        params: JSON.stringify(imgList)
      }).then(function (res) {
        _this10.getParams(8);
      });
    },
    // 校验品类商品推荐
    validateCategory: function validateCategory(list) {
      for (var index = 0; index < list.length; index++) {
        var num = index + 1;

        if (!list[index].main.title) {
          this.$message({
            message: "请添加推荐位" + num + "的主标题",
            type: "warning"
          });
          return false;
        }

        if (!list[index].main.img) {
          this.$message({
            message: "请添加推荐位" + num + "的一级推荐商品图片",
            type: "warning"
          });
          return false;
        }

        if (!list[index].main.jumpUrl) {
          this.$message({
            message: "请添加推荐位" + num + "的一级推荐商品链接",
            type: "warning"
          });
          return false;
        }

        if (list[index].goods.length < 3) {
          this.$message({
            message: "请添加推荐位" + num + "的二级推荐商品",
            type: "warning"
          });
          return false;
        }
      }

      return true;
    },
    // 保存品类商品推荐
    saveCategory: function saveCategory() {
      var _this11 = this;

      var dataList = this.$refs.category.dataList;

      if (this.validateCategory(dataList)) {
        (0, _xcx.saveParams)({
          type: 9,
          status: this.sectionList[this.activeNum - 1].show ? 2 : 1,
          params: JSON.stringify(dataList)
        }).then(function (res) {
          _this11.getParams(9);
        });
      }
    },
    save: function save() {
      switch (this.activeNum) {
        case 1:
          this.saveTopBanner();
          break;

        case 2:
          this.saveNineGrid();
          break;

        case 3:
          this.saveCapsuleBanner();
          break;

        case 4:
          this.saveThirdHot();
          break;

        case 5:
          this.saveBannerCol();
          break;

        case 6:
          this.saveTwoColGoods();
          break;

        case 7:
          this.saveSecBanner();
          break;

        case 8:
          this.saveTwoActivity();
          break;

        case 9:
          this.saveCategory();
          break;

        default:
          break;
      }
    }
  }
};
exports.default = _default;