"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.function.name");

var _audit = require("@/api/audit");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _rateDialog = _interopRequireDefault(require("../category/rateDialog"));

var _setDialog = _interopRequireDefault(require("../category/setDialog"));

var _batchPass = _interopRequireDefault(require("../category/batchPass"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    rateDialog: _rateDialog.default,
    setDialog: _setDialog.default,
    batchPass: _batchPass.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      searchForm: {
        name: "",
        shopname: "",
        ischeck: null,
        create_time: ""
      },
      activeName: "0",
      total: 0,
      tableData: [],
      refuseVisible: false,
      refuseForm: {
        check_refuse_type: "",
        check_refuse_reason: ""
      },
      batchRefuseForm: {
        check_refuse_type: "",
        check_refuse_reason: ""
      },
      refuseList: [{
        value: 1,
        text: "营业执照经营范围不包含该品类"
      }, {
        value: 2,
        text: "其他原因"
      }],
      formList: [{
        source_id: 7,
        is_delete: 0,
        smartcontract: 1.50,
        realname: 0.70,
        risk: 0.00,
        platform: 0.45,
        withhold: 0.60,
        file: 0.45,
        financing: 0.00
      }, {
        source_id: 8,
        is_delete: 0,
        smartcontract: 1.50,
        realname: 0.70,
        risk: 0.00,
        platform: 6.95,
        withhold: 0.60,
        file: 0.45,
        financing: 0.00
      }],
      rules: {
        check_refuse_type: [{
          required: true,
          message: "请选择拒绝原因",
          trigger: "change"
        }]
      },
      refuseId: 0,
      isRequired: false,
      totalNum: 0,
      auditNum: 0,
      approvedNum: 0,
      refusedNum: 0,
      rateVisible: false,
      rateData: [],
      setRateVisible: false,
      selectVal: "",
      submitType: "",
      sourceList: [],
      batchPassVisible: false,
      batchPassData: [],
      dialogTitle: "",
      batchPassTitle: "",
      batchPassTotal: 0,
      isShowRefuseFrom: false,
      isShowRateFrom: false,
      setMemberId: "",
      setCategoryId: "",
      delList: []
    };
  },
  methods: {
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== "error") {
        this.searchForm.create_time = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        name: "",
        shopname: "",
        ischeck: null,
        create_time: ""
      };
      this.getList();
    },
    handleTabsClick: function handleTabsClick(tab) {
      this.activeName = tab.name;
      this.getList(true);
    },
    jumpSupplier: function jumpSupplier(val) {
      this.$router.push({
        path: "/supplier/list",
        query: {
          shopname: val
        }
      });
    },
    checkRate: function checkRate(row) {
      var _this = this;

      this.rateVisible = true;
      (0, _audit.getSourceAndRate)({
        member_id: row.member_id,
        category_id: row.category_id
      }).then(function (res) {
        _this.rateData = res;
      });
    },
    closeDialog: function closeDialog() {
      this.rateVisible = false;
      this.setRateVisible = false;
      this.batchPassVisible = false;
    },
    submitPass: function submitPass(param) {
      var _this2 = this;

      // console.log('submitPass', this.isShowRateFrom, param)
      var ids = "",
          idlist = [],
          goodsRates = [];
      this.batchPassData.forEach(function (item) {
        idlist.push(item.id);
        goodsRates.push({
          member_id: item.member_id,
          category_id: item.category_id
        });
      });
      ids = idlist.join(",");

      if (this.isShowRateFrom) {
        (0, _audit.editUserIscheck)({
          checkType: "pass",
          ischeck: 1,
          idStr: ids,
          goods_rate: goodsRates,
          rate_list: param
        }).then(function (res) {
          _this2.batchPassVisible = false;

          _this2.getList(true);
        });
      } else {
        var _this$batchRefuseForm = this.batchRefuseForm,
            check_refuse_type = _this$batchRefuseForm.check_refuse_type,
            check_refuse_reason = _this$batchRefuseForm.check_refuse_reason;

        if (!check_refuse_type) {
          this.$message.warning("请选择原因");
          return false;
        } else if (check_refuse_type == 2 && !check_refuse_reason) {
          this.$message.warning("请输入其他原因");
          return false;
        }

        (0, _audit.editUserIscheck)({
          checkType: "refuse",
          idStr: ids,
          ischeck: 3,
          check_refuse_type: check_refuse_type,
          check_refuse_reason: check_refuse_reason
        }).then(function (res) {
          _this2.batchPassVisible = false;

          _this2.getList(true);
        });
      }
    },
    deleteFrom: function deleteFrom(item, index) {
      var _this3 = this;

      // this.index = this.sourceForm.moreFroms.indexOf(item)
      // this.formList.splice(index, 1)
      var delData = (0, _toConsumableArray2.default)(this.formList);

      if (delData.length > 0) {
        delData.forEach(function (x, index1) {
          if (index1 == index) {
            x["is_delete"] = 1;

            _this3.formList.splice(index, 1);
          } else {
            x["is_delete"] = 0;
          }
        });
      }

      this.delList = delData;
    },
    addSource: function addSource() {
      this.formList.push({
        source_id: "",
        is_delete: 0,
        smartcontract: 1.50,
        realname: 0.70,
        risk: 0.00,
        platform: 0.45,
        withhold: 0.60,
        file: 0.45,
        financing: 0.00
      });
    },
    submitSource: function submitSource(list) {
      var _this4 = this;

      var rateList = this.delList.length > 0 ? this.delList : list;
      var obj = {
        member_id: this.setMemberId,
        category_id: this.setCategoryId
      };

      if (this.submitType == "set") {
        (0, _audit.setRate)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, obj), {}, {
          rate_list: rateList
        })).then(function (res) {
          _this4.setRateVisible = false;

          _this4.getList();
        });
      } else {
        var params = [].concat(obj); // console.log('通过',this.submitType, list, rateList )

        (0, _audit.editUserIscheck)({
          checkType: "pass",
          ischeck: 1,
          idStr: this.idString,
          goods_rate: params,
          rate_list: rateList
        }).then(function (res) {
          _this4.setRateVisible = false;

          _this4.getList();
        });
      }
    },
    // 批量操作
    handleCommand: function handleCommand(command) {
      var list = this.$refs.multipleTable.selection;
      this.batchPassTotal = list.length;

      if (list.length === 0) {
        this.$message({
          message: "没有勾选项",
          type: "warning"
        });
        return;
      }

      this.setMemberId = list[0].member_id;
      this.setCategoryId = list[0].category_id;
      var ids = list.map(function (item) {
        return item.id;
      }).join(","); // console.log('ids',ids)

      switch (command) {
        case "add":
          this.handleDialog("add", ids, list);
          break;

        case "refuse":
          this.handleDialog("refuse", ids, list);
          break;

        case "delete":
          this.handleDialog("del", ids, list);
          break;

        default:
          break;
      }
    },
    handleDialog: function handleDialog(type, id, list) {
      var _this5 = this;

      console.log("id", id);
      var ischeck = type == "add" ? 1 : 3;
      this.isShowRefuseFrom = false;

      if (type == "add") {
        if (!list) {
          this.idString = id.id;
          this.submitType = "pass";
          this.getRate(id);
        } else {
          this.isShowRateFrom = true;
          this.dialogTitle = "批量通过审核";
          this.batchPassTitle = "是否确认下列商品批量通过审核？";
          this.batchPassVisible = true;
          this.batchPassData = list;
          this.formList = [{
            source_id: 7,
            is_delete: 0,
            smartcontract: 1.50,
            realname: 0.70,
            risk: 0.00,
            platform: 0.45,
            withhold: 0.60,
            file: 0.45,
            financing: 0.00
          }, {
            source_id: 8,
            is_delete: 0,
            smartcontract: 1.50,
            realname: 0.70,
            risk: 0.00,
            platform: 6.95,
            withhold: 0.60,
            file: 0.45,
            financing: 0.00
          }];
          (0, _audit.getSourceInfo)({
            member_id: this.setMemberId,
            category_id: this.setCategoryId
          }).then(function (res) {
            _this5.sourceList = res;
          });
        }
      } else if (type == "del") {
        this.$confirm("您确定要删除吗?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(function () {
          (0, _audit.delUserInfo)({
            idStr: id
          }).then(function (res) {
            _this5.getList();
          });
        });
      } else if (type == "refuse") {
        if (!list) {
          this.refuseForm = Object.assign({});
          this.refuseId = id;
          this.isRequired = false;
          this.refuseVisible = true;
        } else {
          this.dialogTitle = "批量拒绝审核";
          this.batchPassTitle = "是否确认下列商品批量拒绝审核？";
          this.isShowRateFrom = false;
          this.isShowRefuseFrom = true;
          this.batchPassVisible = true;
          this.batchPassData = list;
        }
      } else if (type == "edit") {
        this.submitType = "set";
        this.getRate(id);
      }
    },
    getRate: function getRate(params) {
      var _this6 = this;

      this.formList = [{
        source_id: 7,
        is_delete: 0,
        smartcontract: 1.50,
        realname: 0.70,
        risk: 0.00,
        platform: 0.45,
        withhold: 0.60,
        file: 0.45,
        financing: 0.00
      }, {
        source_id: 8,
        is_delete: 0,
        smartcontract: 1.50,
        realname: 0.70,
        risk: 0.00,
        platform: 6.95,
        withhold: 0.60,
        file: 0.45,
        financing: 0.00
      }];
      this.setRateVisible = true;
      this.setMemberId = params.member_id;
      this.setCategoryId = params.category_id;
      this.delList = [];
      var obj = {
        member_id: params.member_id,
        category_id: params.category_id
      };
      (0, _audit.getSourceAndRate)(obj).then(function (res) {
        if (res.length > 0) {
          res.forEach(function (x) {
            x["is_delete"] = 0;
            _this6.formList = res;
          });
        }
      });
      (0, _audit.getSourceInfo)(obj).then(function (res) {
        _this6.sourceList = res;
      });
    },
    changeSourceId: function changeSourceId(val) {// console.log("val",val)
    },
    changeOption: function changeOption(val) {
      var _this7 = this;

      this.$nextTick(function () {
        if (val == 2) {
          _this7.isRequired = true;
        } else {
          _this7.isRequired = false;
        }
      });
    },
    submitForm: function submitForm(formName) {
      var _this8 = this;

      var _this$refuseForm = this.refuseForm,
          check_refuse_type = _this$refuseForm.check_refuse_type,
          check_refuse_reason = _this$refuseForm.check_refuse_reason;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          // console.log(valid, check_refuse_type, check_refuse_reason);
          if (check_refuse_type == 2) {
            if (!check_refuse_reason) {
              _this8.$message.warning("请输入其他原因");

              return false;
            }
          }

          (0, _audit.editUserIscheck)({
            checkType: "refuse",
            idStr: _this8.refuseId,
            ischeck: 3,
            check_refuse_type: check_refuse_type,
            check_refuse_reason: check_refuse_reason
          }).then(function (res) {
            _this8.refuseVisible = false;

            _this8.getList();
          });
        }
      });
    },
    // 获取列表
    getList: function getList(flag) {
      var _this9 = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _audit.categoryList)({
        page: page,
        pageSize: pageSize,
        name: this.searchForm.name,
        shopname: this.searchForm.shopname,
        ischeck: this.searchForm.ischeck || this.activeName,
        create_time: this.searchForm.create_time
      }).then(function (res) {
        _this9.tableData = res.data || [];

        if (_this9.activeName == 0) {
          _this9.total = res.total;
        } else if (_this9.activeName == 2) {
          _this9.total = res.count.notcheck_total || 0;
        } else if (_this9.activeName == 1) {
          _this9.total = res.count.ischeck_total || 0;
        } else {
          _this9.total = res.count.refusecheck_total || 0;
        }

        _this9.totalNum = res.count.check_total || 0;
        _this9.auditNum = res.count.notcheck_total || 0;
        _this9.approvedNum = res.count.ischeck_total || 0;
        _this9.refusedNum = res.count.refusecheck_total || 0;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
    this.formList = [];
  }
};
exports.default = _default;