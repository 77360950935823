"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.regexp.split");

var _global = _interopRequireDefault(require("../../utils/global"));

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _order = require("@/api/order");

var _checklease = _interopRequireDefault(require("./dialogs/checklease"));

var _setAddress = _interopRequireDefault(require("./dialogs/setAddress"));

var _setDevice = _interopRequireDefault(require("./dialogs/setDevice"));

var _setRemark = _interopRequireDefault(require("./dialogs/setRemark"));

var _checkRemark = _interopRequireDefault(require("./dialogs/checkRemark"));

var _checkFlow = _interopRequireDefault(require("./dialogs/checkFlow"));

var _delivery = _interopRequireDefault(require("./dialogs/delivery"));

var _receipt = _interopRequireDefault(require("./dialogs/receipt"));

var _authInfo = _interopRequireDefault(require("./dialogs/authInfo"));

var _checkBill = _interopRequireDefault(require("./dialogs/checkBill"));

var _checkLogistics = _interopRequireDefault(require("./dialogs/checkLogistics"));

var _checkRisk = _interopRequireDefault(require("./dialogs/checkRisk"));

var _checkRiskTwo = _interopRequireDefault(require("./dialogs/checkRiskTwo"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    myDatePicker: _myDatePicker.default,
    customPage: _customPage.default,
    checkLease: _checklease.default,
    setAddress: _setAddress.default,
    setDevice: _setDevice.default,
    setRemark: _setRemark.default,
    checkRemark: _checkRemark.default,
    checkFlow: _checkFlow.default,
    delivery: _delivery.default,
    receipt: _receipt.default,
    authInfo: _authInfo.default,
    checkBill: _checkBill.default,
    checkLogistics: _checkLogistics.default,
    checkRisk: _checkRisk.default,
    checkRiskTwo: _checkRiskTwo.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: '',
        goodsName: '',
        receivingName: '',
        receivingMobile: '',
        riskName: '',
        riskMobile: '',
        orderStatus: '',
        orderCreateTime: '',
        shopName: ''
      },
      statusList: _global.default.orderStatus,
      total: 0,
      tableData: [],
      activeName: 'all',
      tabList: [{
        id: 'all',
        name: '全部订单',
        count: 0
      }, {
        id: 'notPay',
        name: '待付款订单',
        count: 0
      }, {
        id: 'notRisk',
        name: '待风控订单',
        count: 0
      }, {
        id: 'deliveredNot',
        name: '待发货订单',
        count: 0
      }, {
        id: 'deliveredIs',
        name: '已发货订单',
        count: 0
      }, {
        id: 'orderIng',
        name: '租用中订单',
        count: 0
      }, {
        id: 'orderCompleted',
        name: '交易完成订单',
        count: 0
      }, {
        id: 'orderCancel',
        name: '取消订单',
        count: 0
      }, {
        id: 'orderReturn',
        name: '归还订单',
        count: 0
      }, {
        id: 'orderBuyout',
        name: '买断订单',
        count: 0
      }, {
        id: 'orderRenewal',
        name: '续租订单',
        count: 0
      }, {
        id: 'orderRefund',
        name: '退款订单',
        count: 0
      }],
      selectedId: '',
      editItem: {},
      selectList: [],
      returnVisible: false,
      refuseId: '',
      returnForm: {
        checkRefuseReason: ''
      }
    };
  },
  methods: {
    handleRefundOrder: function handleRefundOrder(tradeNo) {
      this.$router.push({
        name: 'refundList',
        params: {
          tradeNo: tradeNo
        }
      });
    },
    // 查看合同
    checkPact: function checkPact(url) {
      window.open(url);
    },
    // 订单完结
    finishOrder: function finishOrder(id) {
      var _this = this;

      this.$confirm('交易完成后进行解约现有款项不退款，并解约代扣无法发起未代扣的期数！', '请确认是否交易完成？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.orderFinish)(id).then(function (res) {
          _this.getList();
        });
      }).catch(function () {});
    },
    // 同意归还
    agreeReturn: function agreeReturn(id) {
      var _this2 = this;

      this.$confirm('确定要同意归还吗？', '同意归还', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.orderReturnAgree)(id).then(function (res) {
          _this2.getList();
        });
      }).catch(function () {});
    },
    // 拒绝归还
    refuseReturn: function refuseReturn(id) {
      this.refuseId = id;
      this.returnVisible = true;
    },
    // 订单关闭
    closeOrder: function closeOrder(id) {
      var _this3 = this;

      this.$confirm('确定要关闭订单吗？', '关闭', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.orderClose)(id).then(function (res) {
          _this3.getList();
        });
      }).catch(function () {});
    },
    closeReturn: function closeReturn() {
      this.returnVisible = false;
      this.returnForm.checkRefuseReason = '';
    },
    confirmReturnVisible: function confirmReturnVisible() {
      var _this4 = this;

      if (!this.returnForm.checkRefuseReason) {
        this.$message.warning('拒绝原因不能为空');
        return false;
      }

      (0, _order.orderReturnRefuse)({
        orderId: this.refuseId,
        checkRefuseReason: this.returnForm.checkRefuseReason
      }).then(function (res) {
        _this4.getList();

        _this4.closeReturn();
      }).catch(function () {});
    },
    // 确认归还
    confirmReturn: function confirmReturn(id) {
      var _this5 = this;

      this.$confirm('确定要归还吗？', '确认归还', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _order.orderComfirmReturn)(id).then(function (res) {
          _this5.getList();
        });
      }).catch(function () {});
    },
    // 归还完成
    // finishReturn(id) {
    //   orderReturnFinish(id).then((res) => {
    //     this.getList();
    //   });
    // },
    // 查看物流
    checkLogistics: function checkLogistics(id) {
      this.selectedId = id;
      this.$refs.checkLogistics.dialogVisible = true;
    },
    // 查看已付账单详情
    checkPaidBill: function checkPaidBill(id, paid_rent) {
      if (this.$hasMethod('#checkBill') && paid_rent > 0) {
        this.selectedId = id;
        this.$refs.checkBill.dialogVisible = true;
      }
    },
    // 查看认证资料
    checkAuthInfo: function checkAuthInfo(item) {
      this.editItem = {
        idcard_back: item.idcard_back,
        idcard_front: item.idcard_front
      };
      this.$refs.authInfo.dialogVisible = true;
    },
    // 确认收货
    confirmReceipt: function confirmReceipt(id) {
      this.selectedId = id;
      this.$refs.receipt.dialogVisible = true;
    },
    // 订单发货
    openDelivery: function openDelivery(id) {
      this.selectedId = id;
      this.$refs.delivery.dialogVisible = true;
    },
    // 查看流水记录
    viewFlow: function viewFlow(trade_no) {
      this.selectedId = trade_no;
      this.$refs.checkFlow.dialogVisible = true;
    },
    // 查看客服备注
    viewRemark: function viewRemark(id) {
      this.selectedId = id;
      this.$refs.checkRemark.dialogVisible = true;
    },
    // 添加客服备注
    addRemark: function addRemark(id) {
      this.selectedId = id;
      this.$refs.setRemark.dialogVisible = true;
    },
    // 更改设备编号
    editDevice: function editDevice(item) {
      this.editItem = {
        orderId: item.id,
        deviceNumber: item.device_number ? item.device_number.split(',') : [],
        amount: item.amount
      };
      this.$refs.setDevice.dialogVisible = true;
    },
    // 修改地址
    editAddress: function editAddress(item) {
      this.editItem = {
        orderId: item.id,
        realname: item.realname,
        mobile: item.address_mobile,
        address: item.address,
        prov: item.prov,
        city: item.city,
        area: item.area,
        province: [item.prov, item.city, item.area]
      };
      this.$refs.setAddress.dialogVisible = true;
    },
    // 查看租期
    viewLease: function viewLease(id) {
      this.selectedId = id;
      this.$refs.checkLease.dialogVisible = true;
    },
    checkRisk: function checkRisk(aa, types) {
      this.selectedId = {
        order_id: aa.id,
        name: aa.cert_name,
        phone: aa.mobile,
        ident_number: aa.cert_no,
        type: types
      };

      switch (types) {
        case 1:
          this.$refs.checkRisk.dialogVisible = true;
          break;

        case 2:
          this.$refs.checkRiskTwo.dialogVisible = true;
          break;
      }
    },
    getOrderStatusType: function getOrderStatusType(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.type : 'warning';
    },
    getOrderStatusName: function getOrderStatusName(status) {
      var obj = this.statusList.find(function (item) {
        return item.id == status;
      });
      return obj ? obj.name : '';
    },
    // 获取数据
    getList: function getList(flag) {
      var _this6 = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _order.orderList)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        orderStatusGroup: this.activeName
      })).then(function (res) {
        var list = res.list,
            count = res.count;
        _this6.tableData = list.data || [];
        _this6.total = list.total || 0;

        var _iterator = (0, _createForOfIteratorHelper2.default)(_this6.tabList),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            item.count = count[item.id];
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== 'error') {
        this.searchForm.orderCreateTime = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        orderNo: '',
        goodsName: '',
        receivingName: '',
        receivingMobile: '',
        riskName: '',
        riskMobile: '',
        orderStatus: '',
        orderCreateTime: '',
        shopName: ''
      };
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== 'error') {
        this.searchForm.orderCreateTime = pickerVal;
        (0, _order.orderListExport)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, this.searchForm), {}, {
          orderStatusGroup: this.activeName
        }));
      }
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    getSelect: function getSelect() {
      var _this7 = this;

      (0, _order.getOrderStatus)().then(function (res) {
        var list = [];

        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }

        _this7.selectList = list || [];
      });
    }
  },
  mounted: function mounted() {
    if (this.$route.params.orderId) {
      this.searchForm.orderNo = this.$route.params.orderId;
    }

    this.getSelect();
    this.getList();
  }
};
exports.default = _default;