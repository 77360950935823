"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

var _user = require("@/api/user");

var _audit = require("@/api/audit");

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    cropUpload: _cropUpload.default
  },
  props: ["id"],
  data: function data() {
    return {
      form: {
        phone: '',
        alias_name: '',
        alipay_logon_id: '',
        address: '',
        imgurl_neij: '',
        imgurl_waij: '',
        imgurl_yyzz: '',
        address_code: [],
        address_status: '0'
      },
      options: [],
      jieguo: ''
    };
  },
  mounted: function mounted() {
    this.getList();
    this.getareas();
  },
  methods: {
    getList: function getList() {
      var _this = this;

      (0, _user.getMerchantApply)({
        member_id: this.id
      }).then(function (res) {
        // this.form = res;
        if (Object.keys(res).length > 0) {
          _this.form = res;
        }
      });
    },
    getareas: function getareas() {
      var _this2 = this;

      (0, _audit.getGoodsAreas)({
        type: 1
      }).then(function (res) {
        _this2.options = res;
      });
    },
    onSubmit: function onSubmit() {
      var _this3 = this;

      var data = this.form;
      data.member_id = this.id;
      console.log(data.address_code);

      if (data.phone == '' || data.alias_name == '' || data.alipay_logon_id == '' || data.address == '' || data.imgurl_neij == '' || data.imgurl_waij == '' || data.imgurl_yyzz == '' || data.address_code.length == 0) {
        this.$message.error('参数都必填');
        return false;
      }

      (0, _user.saveMerchantApply)(data).then(function (res) {
        _this3.getList();
      });
    },
    checkJJCodeStatus: function checkJJCodeStatus() {
      var _this4 = this;

      (0, _user.mayiMerchantzftQuery)({
        member_id: this.id
      }).then(function (res) {
        _this4.getList();
      });
    },
    getorderstatustype: function getorderstatustype(status) {
      if (status == "99") {
        return "进件流程结束，已可使用收款流程";
      } else if (status == "031") {
        return "审核中，请前往支付宝号商家平台->账号中心->签约中心 签约 支付宝收单结算服务";
      } else {
        return "数据异常 联系技术处理";
      }
    },
    setUrl: function setUrl(val) {
      console.log(val);
      this.form.imgurl_neij = val;
    },
    setUrl2: function setUrl2(val) {
      console.log(val);
      this.form.imgurl_waij = val;
    },
    setUrl3: function setUrl3(val) {
      console.log(val);
      this.form.imgurl_yyzz = val;
    },
    handleChange: function handleChange(value) {}
  }
};
exports.default = _default;