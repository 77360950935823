"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upload = upload;
exports.getOrderStatus = getOrderStatus;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// 上传文件
function upload(params) {
  var memberId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var sizeLimit = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var riginalName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  params.append('sizeLimit', sizeLimit);
  params.append('riginalName', riginalName);
  params.append('memberId', memberId);
  return _fetch.default.upload('/Admin/upload', params);
} // 获取所有订单状态


function getOrderStatus() {
  return _fetch.default.get('/Admin/Currency/getOrderStatus');
}