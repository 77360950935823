var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: {
        width: "500px",
        title: "更新小程序",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "el-form",
        { ref: "ruleForm", attrs: { model: _vm.form, "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "更新渠道" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.type,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type"
                  }
                },
                [
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "alipay" }
                    },
                    [_vm._v("支付宝")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "amap" }
                    },
                    [_vm._v("高德地图\t")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "quark" }
                    },
                    [_vm._v("夸克")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "ampe" }
                    },
                    [_vm._v("AMPE设备\t")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "alios" }
                    },
                    [_vm._v("阿里车\t")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "genie" }
                    },
                    [_vm._v("天猫精灵\t")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "koubei" }
                    },
                    [_vm._v("口碑\t")]
                  ),
                  _c(
                    "el-radio",
                    { staticStyle: { width: "60px" }, attrs: { label: "uc" } },
                    [_vm._v("UC浏览器")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "alihealth" }
                    },
                    [_vm._v("医蝶谷")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "health" }
                    },
                    [_vm._v("阿里医院\t")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "cainiao" }
                    },
                    [_vm._v("菜鸟")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "alipayiot" }
                    },
                    [_vm._v("IoT设备\t")]
                  ),
                  _c(
                    "el-radio",
                    {
                      staticStyle: { width: "60px" },
                      attrs: { label: "weixin" }
                    },
                    [_vm._v("微信包")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.submit }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }