"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _web = require("@/api/web");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _article = require("@/api/article");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      articleFrom: {
        article_title: "",
        status: "",
        parentid: "",
        publish_time: ""
      },
      topTypeList: [],
      tableData: [],
      total: 0,
      handleId: "",
      searchObj: {}
    };
  },
  mounted: function mounted() {
    this.getTopType();
    this.getTableLists();
  },
  methods: {
    getTopType: function getTopType() {
      var _this = this;

      (0, _web.getInfoTypeList)().then(function (res) {
        _this.topTypeList = res;
      });
    },
    getTableLists: function getTableLists(articleFrom) {
      var _this2 = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      var obj = (0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, articleFrom);
      (0, _article.getArticleList)(obj).then(function (res) {
        _this2.tableData = res.data;
        _this2.total = res.total;
      });
    },
    changeRecommend: function changeRecommend(type, id, recommend) {
      var _this3 = this;

      if (type == "home") {
        (0, _article.editHome)({
          id: id,
          home_recommend: recommend == 1 ? "2" : "1"
        }).then(function (res) {
          _this3.getTableLists();
        });
      } else if (type == "hot") {
        (0, _article.editHot)({
          id: id,
          hot_recommend: recommend == 1 ? "2" : "1"
        }).then(function (res) {
          _this3.getTableLists();
        });
      } else {
        (0, _article.editStatus)({
          id: id,
          status: recommend == 1 ? "2" : "1"
        }).then(function (res) {
          _this3.getTableLists();
        });
      }
    },
    handleBtn: function handleBtn(id) {
      var _this4 = this;

      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _article.delArticle)(id).then(function (res) {
          _this4.getTableLists();
        });
      }).catch(function () {});
    },
    onSubmit: function onSubmit() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== 'error') {
        this.articleFrom.publish_time = pickerVal;
        this.searchObj = (0, _objectSpread2.default)({}, this.articleFrom);
        this.$refs.customPage.page = 1;
        this.getTableLists(this.searchObj);
      }
    },
    onReset: function onReset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.articleFrom = Object.assign({});
      this.getTableLists();
    }
  }
};
exports.default = _default;