"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _finance = require("@/api/finance");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  props: ['orderNo', 'leaseId', 'orderId'],
  data: function data() {
    return {
      activeName: 'deduct',
      dialogVisible: false,
      tableData: [],
      total: 0
    };
  },
  methods: {
    getDeduct: function getDeduct() {
      var _this = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      var pageInfo = {
        page: page,
        pageSize: pageSize
      };

      if (this.activeName == 'deduct') {
        (0, _finance.leaseWithholdLog)((0, _objectSpread2.default)({
          orderNo: this.orderNo,
          leaseId: this.leaseId
        }, pageInfo)).then(function (res) {
          _this.tableData = res.data || [];
          _this.total = res.total || 0;
        });
      } else {
        this.tableData = [];
        (0, _finance.getOrderLog)((0, _objectSpread2.default)({
          order_id: this.orderId
        }, pageInfo)).then(function (res) {
          _this.tableData = res.data || [];
          _this.total = res.total || 0;
        });
      }
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.getDeduct();
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        this.$nextTick(function () {
          _this2.getDeduct();
        });
        this.activeName = 'deduct';
      }
    }
  }
};
exports.default = _default;