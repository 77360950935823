"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGoodsList = getGoodsList;
exports.getBannerList = getBannerList;
exports.updateBannerStatus = updateBannerStatus;
exports.sortBanner = sortBanner;
exports.deleteBanner = deleteBanner;
exports.addBanner = addBanner;
exports.getBannerById = getBannerById;
exports.getCategory = getCategory;
exports.getCategoryGoodsById = getCategoryGoodsById;
exports.addCategoryGoods = addCategoryGoods;
exports.getCategoryList = getCategoryList;
exports.getActivity = getActivity;
exports.getActivityGoods = getActivityGoods;
exports.addActivityGoods = addActivityGoods;
exports.getCustomModule = getCustomModule;
exports.getCustomModuleById = getCustomModuleById;
exports.addCustomModule = addCustomModule;
exports.h5BannerSetList = h5BannerSetList;
exports.addOrEditH5BannerSet = addOrEditH5BannerSet;
exports.h5BannerSetById = h5BannerSetById;
exports.delH5BannerSet = delH5BannerSet;
exports.h5HomeActivitiesPlateList = h5HomeActivitiesPlateList;
exports.getActivitiesModuleGoodsByModuleid = getActivitiesModuleGoodsByModuleid;
exports.addOrEditActivitiesModuleGoods = addOrEditActivitiesModuleGoods;
exports.h5HomeCustomPlateList = h5HomeCustomPlateList;
exports.getCustomModuleGoodsByModuleid = getCustomModuleGoodsByModuleid;
exports.addOrEditCustomModuleGoods = addOrEditCustomModuleGoods;
exports.h5PromotionCateList = h5PromotionCateList;
exports.h5PromotionCateById = h5PromotionCateById;
exports.addOrEditH5PromotionCate = addOrEditH5PromotionCate;
exports.delH5PromotionCate = delH5PromotionCate;
exports.h5PromotionGoodsList = h5PromotionGoodsList;
exports.h5PromotionCate = h5PromotionCate;
exports.chooseGoods = chooseGoods;
exports.addOrEditH5PromotionGoods = addOrEditH5PromotionGoods;
exports.getSecondCategory = getSecondCategory;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// 获取商品列表
function getGoodsList(params) {
  return _fetch.default.get("/Admin/Goods/getGoodsList", params);
}
/**
 * 商城banner设置
 */
// 获取轮播图


function getBannerList(params) {
  return _fetch.default.get("/Admin/Banner/getBannerList", params);
} // 更改轮播状态（上架、下架）


function updateBannerStatus(id) {
  return _fetch.default.post("/Admin/Banner/updateBannerStatus", {
    banner_id: id
  }, true);
} // 轮播图排序


function sortBanner(params) {
  return _fetch.default.post("/Admin/Banner/sortBanner", params, true);
} // 删除轮播图


function deleteBanner(id) {
  return _fetch.default.post("/Admin/Banner/deleteBanner", {
    banner_id: id
  }, true);
} // 添加、编辑轮播图


function addBanner(params) {
  return _fetch.default.post("/Admin/Banner/addBanner", params, true);
} // 根据id获取轮播图信息


function getBannerById(id) {
  return _fetch.default.get("/Admin/Banner/getBannerById", {
    banner_id: id
  });
}
/**
 * 首页品类商品推荐
 */
// 获取首页分类


function getCategory() {
  return _fetch.default.get("/Admin/Category/getCategory");
} // 根据id获取分类下推荐商品


function getCategoryGoodsById(id) {
  return _fetch.default.get("/Admin/Category/getCategoryGoodsById", {
    cate_id: id
  });
} // 添加、编辑分类推荐商品


function addCategoryGoods(params) {
  return _fetch.default.post("/Admin/Category/addCategoryGoods", params, true);
} // 获取筛选全部二级分类


function getCategoryList() {
  return _fetch.default.get("/Admin/Goods/getCategory");
}
/**
 * 首页活动板块
 */
// 获取首页活动板块Title


function getActivity() {
  return _fetch.default.get("/Admin/Module/activity");
} // 根据活动id获取关联商品


function getActivityGoods(id) {
  return _fetch.default.get("/Admin/Module/getActivityGoods", {
    module_id: id
  });
} // 添加/修改首页活动关联商品


function addActivityGoods(params) {
  return _fetch.default.post("/Admin/Module/addActivityGoods", params, true);
}
/**
 * 首页活动板块
 */
// 获取首页自定义板块Title


function getCustomModule() {
  return _fetch.default.get("/Admin/Module/getCustomModule");
} // 根据id获取自定义关联商品


function getCustomModuleById(id) {
  return _fetch.default.get("/Admin/Module/getCustomModuleById", {
    module_id: id
  });
} // 添加/修改自定义板块及关联商品


function addCustomModule(params) {
  return _fetch.default.post("/Admin/Module/addCustomModule", params, true);
}
/**
 * H5端
 */
// H5端banner设置列表


function h5BannerSetList(params) {
  return _fetch.default.post("/Admin/H5BannerSet/h5BannerSetList", params);
} // 添加或编辑H5端banner设置


function addOrEditH5BannerSet(params) {
  return _fetch.default.post("/Admin/H5BannerSet/addOrEditH5BannerSet", params, true);
} // 根据id查询H5端banner设置


function h5BannerSetById(id) {
  return _fetch.default.get("/Admin/H5BannerSet/h5BannerSetById", {
    id: id
  });
} // 根据id删除H5端banner设置


function delH5BannerSet(id) {
  return _fetch.default.post("/Admin/H5BannerSet/delH5BannerSet", {
    id: id
  }, true);
} // H5端首页活动板块列表


function h5HomeActivitiesPlateList() {
  return _fetch.default.get("/Admin/H5HomePlate/h5HomeActivitiesPlateList");
} // 根据module_id获取首页活动模块关联商品


function getActivitiesModuleGoodsByModuleid(module_id) {
  return _fetch.default.get("/Admin/H5HomePlate/getActivitiesModuleGoodsByModuleid", {
    module_id: module_id
  });
} // 保存首页活动模块关联商品


function addOrEditActivitiesModuleGoods(params) {
  return _fetch.default.post("/Admin/H5HomePlate/addOrEditActivitiesModuleGoods", params, true);
} // H5端首页自定义板块列表


function h5HomeCustomPlateList() {
  return _fetch.default.get("/Admin/H5HomePlate/h5HomeCustomPlateList");
} // 根据module_id获取首页自定义模块关联商品


function getCustomModuleGoodsByModuleid(module_id) {
  return _fetch.default.get("/Admin/H5HomePlate/getCustomModuleGoodsByModuleid", {
    module_id: module_id
  });
} // 添加编辑首页自定义模块关联商品


function addOrEditCustomModuleGoods(params) {
  return _fetch.default.post("/Admin/H5HomePlate/addOrEditCustomModuleGoods", params, true);
} // H5端商品推广分类列表


function h5PromotionCateList(params) {
  return _fetch.default.post("/Admin/H5Promotion/h5PromotionCateList", params);
} // 根据id查询H5端商品推广分类


function h5PromotionCateById(id) {
  return _fetch.default.get("/Admin/H5Promotion/h5PromotionCateById", {
    id: id
  });
} // 添加或编辑H5端商品推广分类


function addOrEditH5PromotionCate(params) {
  return _fetch.default.post("/Admin/H5Promotion/addOrEditH5PromotionCate", params, true);
} // 根据id删除H5端商品推广分类


function delH5PromotionCate(id) {
  return _fetch.default.post("/Admin/H5Promotion/delH5PromotionCate", {
    id: id
  }, true);
} // H5端商品推广列表


function h5PromotionGoodsList(params) {
  return _fetch.default.post("/Admin/H5Promotion/h5PromotionGoodsList", params);
} // 获取H5端商品推广分类


function h5PromotionCate() {
  return _fetch.default.get("/Admin/H5Promotion/h5PromotionCate");
} // 选择商品


function chooseGoods(params) {
  return _fetch.default.post("/Admin/H5Promotion/chooseGoods", params);
} // 添加或编辑H5端商品推广


function addOrEditH5PromotionGoods(params) {
  return _fetch.default.post("/Admin/H5Promotion/addOrEditH5PromotionGoods", params, true);
} // 获取全部二级分类


function getSecondCategory() {
  return _fetch.default.get("/Admin/Currency/getSecondCategory");
}