"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getProof = getProof;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

function getProof(params) {
  return _fetch.default.get("/Antchain/logistics/proof", params);
}