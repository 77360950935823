"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find-index");

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

require("core-js/modules/es6.regexp.split");

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.string.sub");

require("core-js/modules/es6.function.name");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _defineProperty2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/defineProperty"));

var _previewImg = _interopRequireDefault(require("@/components/previewImg"));

var _user = require("@/api/user");

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

var _validate = require("@/utils/validate");

var _order = require("@/api/order");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    previewImg: _previewImg.default,
    cropUpload: _cropUpload.default
  },
  props: ["viewId", "toEdit"],
  data: function data() {
    var _rules;

    var validateMobile = function validateMobile(rule, value, callback) {
      if (!(0, _validate.checkPhone)(value) && !(0, _validate.checkMobile)(value)) {
        callback(new Error('客服电话格式错误'));
      } else {
        callback();
      }
    };

    var validateTel = function validateTel(rule, value, callback) {
      if (!(0, _validate.checkMobile)(value)) {
        callback(new Error('联系人手机号格式错误'));
      } else {
        callback();
      }
    };

    var validateCompanyId = function validateCompanyId(rule, value, callback) {
      if (!(0, _validate.checkCompanyId)(value)) {
        callback(new Error('工商注册号格式错误'));
      } else {
        callback();
      }
    };

    var validateIdcard = function validateIdcard(rule, value, callback) {
      if (!(0, _validate.checkCardId)(value)) {
        callback(new Error('法人身份证号格式错误'));
      } else {
        callback();
      }
    };

    var validateName = function validateName(rule, value, callback) {
      if (value && !(0, _validate.checkName)(value)) {
        callback(new Error('支付宝姓名格式错误'));
      } else {
        callback();
      }
    };

    var validateAccount = function validateAccount(rule, value, callback) {
      if (value && !(0, _validate.checkAlipayAccount)(value)) {
        callback(new Error('支付宝账号格式错误'));
      } else {
        callback();
      }
    };

    var validateCount = function validateCount(rule, value, callback) {
      if (/^(\-|\+)?\d+(\.\d+)?$/.test(value)) {
        callback();
      } else {
        callback(new Error('数量输入错误'));
      }
    };

    var validateEmail = function validateEmail(rule, value, callback) {
      if (value && !(0, _validate.checkEmail)(value)) {
        callback(new Error('店铺邮箱格式错误'));
      } else {
        callback();
      }
    };

    return {
      activeName: "base",
      isEdit: false,
      tableData: [],
      viewForm: {},
      rules: (_rules = {
        shopname: [{
          required: true,
          message: '店铺名称不能为空',
          trigger: 'blur'
        }],
        account: [{
          required: true,
          message: '请选择账户类型',
          trigger: 'change'
        }],
        account_type: [{
          required: true,
          message: '请选择是否开启风控',
          trigger: 'change'
        }],
        faptype: [{
          required: true,
          message: '请选择发票类型',
          trigger: 'change'
        }],
        shopcity: [{
          required: true,
          message: '当前经营位置不能为空',
          trigger: 'change'
        }],
        people: [{
          required: true,
          message: '店铺联系人不能为空',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '客服电话不能为空',
          trigger: 'blur'
        }, {
          validator: validateMobile,
          trigger: 'blur'
        }],
        contact_tel: [{
          required: true,
          message: '联系人手机号不能为空',
          trigger: 'blur'
        }, {
          validator: validateTel,
          trigger: 'blur'
        }],
        contract_type: [{
          required: true,
          message: '请签署合同类型',
          trigger: 'change'
        }],
        sign_num: [{
          required: true,
          message: '请选择签署方式',
          trigger: 'change'
        }],
        mechanism: [{
          required: true,
          message: '公司名称不能为空',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '店铺邮箱不能为空',
          trigger: 'blur'
        }, {
          validator: validateEmail,
          trigger: 'blur'
        }],
        companyid: [{
          required: true,
          message: '工商注册号不能为空',
          trigger: 'blur'
        }, {
          validator: validateCompanyId,
          trigger: 'blur'
        }],
        company_area: [{
          required: true,
          message: '请选择公司所在区域',
          trigger: 'change'
        }],
        company: [{
          required: true,
          message: '公司地址不能为空',
          trigger: 'blur'
        }],
        faname: [{
          required: true,
          message: '法人姓名不能为空',
          trigger: 'blur'
        }],
        idcard: [{
          required: true,
          message: '法人身份证号不能为空',
          trigger: 'blur'
        }, {
          validator: validateIdcard,
          trigger: 'blur'
        }],
        shopcard: [{
          required: true,
          message: '请上传营业执照',
          trigger: 'change'
        }],
        photo: [{
          required: true,
          message: '请上传店铺头像',
          trigger: 'change'
        }],
        doorphoto: [{
          required: true,
          message: '请上传公司门头照',
          trigger: 'change'
        }],
        seal_url: [{
          required: true,
          message: '请上传公章图片',
          trigger: 'change'
        }],
        faidcard: [{
          required: true,
          message: '请上传身份证正面照',
          trigger: 'change'
        }],
        faidcard_back: [{
          required: true,
          message: '请上传身份证背面照',
          trigger: 'change'
        }]
      }, (0, _defineProperty2.default)(_rules, "company_area", [{
        required: true,
        message: '请选择公司所在区域',
        trigger: 'change'
      }]), (0, _defineProperty2.default)(_rules, "is_city_location", [{
        required: true,
        message: '请选择是否开启城市定位',
        trigger: 'change'
      }]), (0, _defineProperty2.default)(_rules, "alipayaccount", [// { required: true, message: '支付宝账号不能为空', trigger: 'blur' },
      {
        validator: validateAccount,
        trigger: 'blur'
      }]), (0, _defineProperty2.default)(_rules, "sms_num", [{
        required: true,
        message: '短信剩余数量不能为空',
        trigger: 'blur'
      }, {
        validator: validateCount,
        trigger: 'blur'
      }]), (0, _defineProperty2.default)(_rules, "risk_credit_amount", [{
        required: true,
        message: '风控余量不能为空',
        trigger: 'blur'
      }, {
        validator: validateCount,
        trigger: 'blur'
      }]), _rules),
      optionProps: {
        value: 'name',
        label: 'name',
        expandTrigger: 'hover',
        children: 'sub'
      },
      regionList: [],
      areaList: [],
      shopcity: '',
      companyArea: ''
    };
  },
  methods: {
    getAreaId: function getAreaId(arr) {
      var _iterator = (0, _createForOfIteratorHelper2.default)(this.areaList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var p = _step.value;

          if (p.name == arr[0]) {
            var _iterator2 = (0, _createForOfIteratorHelper2.default)(p.sub),
                _step2;

            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var c = _step2.value;

                if (c.name == arr[1]) {
                  return c.id;
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return '';
    },
    contractTypeChange: function contractTypeChange(val) {
      if (val == 1) {
        this.viewForm.sign_num = 1;
      }
    },
    accountTypeChange: function accountTypeChange(val) {
      if (val == 1) {
        this.viewForm.account_type = 1;
      }
    },
    setUrl: function setUrl(key, value) {
      this.viewForm[key] = value;
    },
    handleTabClick: function handleTabClick(tab, event) {// this.cancelEdit();
    },
    // 取消更改 
    cancelEdit: function cancelEdit() {
      if (this.toEdit) {
        this.$router.back();
      } else {
        this.isEdit = false;
        this.getData();
      }
    },
    // 保存提交
    submit: function submit() {
      var _this = this;

      this.$refs['viewForm'].validate(function (valid) {
        if (valid) {
          var params = {
            photo: "",
            shopname: "",
            account: null,
            account_type: null,
            faptype: "",
            shopcity: "",
            people: "",
            mobile: "",
            email: "",
            contact_tel: "",
            contract_type: null,
            sign_num: null,
            mechanism: "",
            companyid: "",
            company_area: "",
            area_id: "",
            company: "",
            faname: "",
            idcard: "",
            shopcard: "",
            doorphoto: "",
            seal_url: "",
            faidcard: "",
            faidcard_back: "",
            is_city_location: 2,
            payeename: "",
            alipayaccount: "",
            sms_num: 0,
            risk_credit_status: "",
            risk_credit_amount: ""
          };

          for (var key in params) {
            params[key] = _this.viewForm[key];
          }

          params.id = _this.viewId;
          params.area_id = _this.getAreaId(params.company_area);
          params.company_area = params.company_area.join('-');
          params.shopcity = params.shopcity.join('-'); // console.log(params);

          (0, _user.editSupplier)(params).then(function (res) {
            _this.isEdit = false;

            _this.getData();
          });
        } else {
          _this.$message({
            message: "表单信息不完整或有错误，请检查完整表单",
            type: "error"
          });

          return false;
        }
      });
    },
    getData: function getData() {
      var _this2 = this;

      (0, _user.getSupplierInfo)(this.viewId).then(function (res) {
        // console.log(res);
        _this2.viewForm = (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, res.shopInfo), res.companyInfo), res.withdrawInfo), res.incrementInfo), res.functionSetInfo);
        _this2.tableData = res.operationRecord;
        _this2.shopcity = _this2.viewForm.shopcity;

        var cityArr = _this2.viewForm.shopcity.split('-');

        _this2.viewForm.shopcity = cityArr;
        _this2.companyArea = _this2.viewForm.company_area;

        var areaArr = _this2.viewForm.company_area.split('-');

        _this2.viewForm.company_area = areaArr;

        if (_this2.toEdit) {
          _this2.isEdit = true;
          _this2.$route.meta.title = "编辑供应商资料";
        } else {
          _this2.$route.meta.title = "查看供应商资料";
        }
      });
    },
    getProvice: function getProvice() {
      var _this3 = this;

      (0, _order.getRegion)().then(function (res) {
        _this3.regionList = res ? res[0].sub : [];
        _this3.areaList = res ? (0, _toConsumableArray2.default)(res[0].sub) : [];

        var index = _this3.areaList.findIndex(function (k) {
          return k.id == 7459;
        });

        _this3.areaList.splice(index, 1);
      });
    }
  },
  mounted: function mounted() {
    this.getData();
    this.getProvice();
  }
};
exports.default = _default;