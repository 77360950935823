"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

var _finance = require("@/api/finance");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      searchForm: {
        orderNo: '',
        goodsName: '',
        receivingName: '',
        receivingMobile: '',
        orderStatus: '',
        leaseTerm: '',
        orderCreateTime: '',
        leaseTime: '',
        orderPayTime: '',
        refundTime: '',
        shopName: ''
      },
      tableData: [],
      total: 0,
      selectList: []
    };
  },
  methods: {
    goToOrder: function goToOrder(orderId) {
      if (orderId) {
        this.$router.push({
          name: 'orderList',
          params: {
            orderId: orderId
          }
        });
      }
    },
    // 获取数据
    getList: function getList() {
      var _this = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;
      (0, _finance.financeLeaseList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total || 0;
      });
    },
    // 查询
    search: function search() {
      var pickerVal1 = this.$refs.makeOrderPicker.getVal();
      var pickerVal2 = this.$refs.withholdPicker.getVal();
      var pickerVal3 = this.$refs.payPicker.getVal();
      var pickerVal4 = this.$refs.refundPicker.getVal();

      if (pickerVal1 == 'error' || pickerVal2 == 'error' || pickerVal3 == 'error' || pickerVal4 == 'error') {
        return;
      }

      this.searchForm.orderCreateTime = pickerVal1;
      this.searchForm.leaseTime = pickerVal2;
      this.searchForm.orderPayTime = pickerVal3;
      this.searchForm.refundTime = pickerVal4;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      if (this.$refs.makeOrderPicker) {
        this.$refs.makeOrderPicker.reset();
      }

      if (this.$refs.withholdPicker) {
        this.$refs.withholdPicker.reset();
      }

      if (this.$refs.payPicker) {
        this.$refs.payPicker.reset();
      }

      if (this.$refs.refundPicker) {
        this.$refs.refundPicker.reset();
      }

      this.searchForm = {
        orderNo: '',
        goodsName: '',
        receivingName: '',
        receivingMobile: '',
        orderStatus: '',
        leaseTerm: '',
        orderCreateTime: '',
        leaseTime: '',
        orderPayTime: '',
        refundTime: '',
        shopName: ''
      };
      this.getList();
    },
    // 导出
    exportFile: function exportFile() {
      (0, _finance.leaseListExport)(this.searchForm);
    },
    getSelect: function getSelect() {
      var _this2 = this;

      (0, _finance.getOrderStatus)().then(function (res) {
        var list = [];

        for (var key in res) {
          list.push({
            id: key,
            name: res[key]
          });
        }

        _this2.selectList = list || [];
      });
    }
  },
  mounted: function mounted() {
    this.getSelect();
    this.getList();
  }
};
exports.default = _default;