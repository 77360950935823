"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.auditSettleList = auditSettleList;
exports.getSettleData = getSettleData;
exports.orderRefuseSettle = orderRefuseSettle;
exports.cancelRefuseSettle = cancelRefuseSettle;
exports.orderSettle = orderSettle;
exports.huebeiSettleList = huebeiSettleList;
exports.getHuabeiSettleData = getHuabeiSettleData;
exports.huabeiOrderSettle = huabeiOrderSettle;
exports.huabeiRefuseSettle = huabeiRefuseSettle;
exports.huabeiCancelRefuseSettle = huabeiCancelRefuseSettle;
exports.orderRefundCheckList = orderRefundCheckList;
exports.getOrderStatus = getOrderStatus;
exports.financeOrderList = financeOrderList;
exports.financeOverdueList = financeOverdueList;
exports.withdrawalList = withdrawalList;
exports.reconciliationFlow = reconciliationFlow;
exports.getFeeDetails = getFeeDetails;
exports.financeLeaseList = financeLeaseList;
exports.getLeaseFlow = getLeaseFlow;
exports.orderListExport = orderListExport;
exports.leaseListExport = leaseListExport;
exports.overdueListExport = overdueListExport;
exports.reconciliationExport = reconciliationExport;
exports.manualWithholding = manualWithholding;
exports.getOrderRemark = getOrderRemark;
exports.getOverdueSmsLog = getOverdueSmsLog;
exports.sendOverdueSms = sendOverdueSms;
exports.setOrderRemark = setOrderRemark;
exports.leaseWithholdLog = leaseWithholdLog;
exports.withdrawalListExport = withdrawalListExport;
exports.financeHuaBeiList = financeHuaBeiList;
exports.huaBeiListExport = huaBeiListExport;
exports.financeEquipmentList = financeEquipmentList;
exports.equipmentListExport = equipmentListExport;
exports.financeCollectionList = financeCollectionList;
exports.collectionListExport = collectionListExport;
exports.orderLeaseSettleExport = orderLeaseSettleExport;
exports.huebeiExport = huebeiExport;
exports.withholdSwitch = withholdSwitch;
exports.getOfflinePayData = getOfflinePayData;
exports.offlinePaySendCode = offlinePaySendCode;
exports.setOfflinePay = setOfflinePay;
exports.getOrderLog = getOrderLog;
exports.getRateList = getRateList;
exports.editRate = editRate;
exports.getAdminFlowList = getAdminFlowList;
exports.getAdminFlowSearchList = getAdminFlowSearchList;
exports.getRateShop = getRateShop;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

/**
 * 账单结算
 */
// 结算审核列表
function auditSettleList(params) {
  return _fetch.default.post("/Admin/FinanceCheck/orderLeaseSettleList", params);
} // 获取结算详情


function getSettleData(id) {
  return _fetch.default.get("/Admin/FinanceCheck/getSettleData", {
    lease_id: id
  });
} // 拒绝结算


function orderRefuseSettle(params) {
  return _fetch.default.post("/Admin/FinanceCheck/orderRefuseSettle", params, true);
} // 撤销拒绝结算


function cancelRefuseSettle(id) {
  return _fetch.default.post("/Admin/FinanceCheck/cancelRefuseSettle", {
    lease_id: id
  }, true);
} // 账单结算--单期结算


function orderSettle(id) {
  return _fetch.default.post("/Admin/FinanceCheck/orderSettle", {
    lease_id: id
  }, true);
}
/**
 * 花呗账单
 */
// 花呗订单结算列表


function huebeiSettleList(params) {
  return _fetch.default.post("/Admin/FinanceCheck/huebeiOrderSettleList", params);
} // 获取花呗结算详情


function getHuabeiSettleData(id) {
  return _fetch.default.get("/Admin/FinanceCheck/getHuabeiOrderSettleData", {
    order_id: id
  });
} // 花呗订单结算


function huabeiOrderSettle(id) {
  return _fetch.default.post("/Admin/FinanceCheck/huabeiOrderSettle", {
    order_id: id
  }, true);
} // 花呗订单拒绝结算


function huabeiRefuseSettle(params) {
  return _fetch.default.post("/Admin/FinanceCheck/huabeiOrderRefuseSettle", params, true);
} // 花呗订单撤销拒绝结算


function huabeiCancelRefuseSettle(id) {
  return _fetch.default.post("/Admin/FinanceCheck/huabeiCancelRefuseSettle", {
    order_id: id
  }, true);
} // 订单退款审核列表


function orderRefundCheckList(params) {
  return _fetch.default.post("/Admin/FinanceCheck/orderRefundCheckList", params);
} // 筛选获取所有订单状态


function getOrderStatus() {
  return _fetch.default.post("/Admin/Finance/getOrderStatus");
} // 财务订单列表


function financeOrderList(params) {
  return _fetch.default.post("/Admin/Finance/financeOrderList", params);
} // 财务逾期列表


function financeOverdueList(params) {
  return _fetch.default.post("/Admin/Finance/financeOverdueList", params);
} // 提现记录


function withdrawalList(params) {
  return _fetch.default.post("/Admin/Withdrawal/withdrawalList", params);
} // 财务对账流水


function reconciliationFlow(params) {
  return _fetch.default.post("/Admin/Finance/reconciliationFlow", params);
} // 获取手续费率和手续费详情


function getFeeDetails(params) {
  return _fetch.default.post("/Admin/Finance/getFeeDetails", params);
} // 账单财务列表


function financeLeaseList(params) {
  return _fetch.default.post("/Admin/Finance/financeLeaseList", params);
} // 获取记录流水


function getLeaseFlow(params) {
  return _fetch.default.post("/Admin/Finance/getLeaseFlow", params);
} // 财务订单导出


function orderListExport(params) {
  return _fetch.default.export("/Admin/Finance/orderListExport", params, "订单财务列表");
} // 财务租期列表导出


function leaseListExport(params) {
  return _fetch.default.export("/Admin/Finance/leaseListExport", params, "账单财务列表");
} // 财务逾期列表导出


function overdueListExport(params) {
  return _fetch.default.export("/Admin/Finance/overdueListExport", params, "逾期列表");
} // 财务对账流水导出


function reconciliationExport(params) {
  return _fetch.default.export("/Admin/Finance/reconciliationExport", params, "对账流水");
} // 手动代扣


function manualWithholding(params) {
  return _fetch.default.post("/Admin/Finance/manualWithholding", params, true);
} // 逾期列表-客服备注记录


function getOrderRemark(params) {
  return _fetch.default.post("/Admin/Finance/getOrderRemark", params);
} // 逾期列表-短信发送记录


function getOverdueSmsLog(leaseId) {
  return _fetch.default.post("/Admin/Finance/getOverdueSmsLog", {
    leaseId: leaseId
  });
} // 逾期列表-逾期短信批量发送


function sendOverdueSms(leaseId) {
  return _fetch.default.post("/Admin/Finance/sendOverdueSms", {
    leaseId: leaseId
  });
} // 添加客服备注


function setOrderRemark(params) {
  return _fetch.default.post("/Admin/Finance/setOrderRemark", params, true);
} // 查看扣款记录


function leaseWithholdLog(params) {
  return _fetch.default.post("/Admin/Finance/leaseWithholdLog", params);
} // 提现记录导出


function withdrawalListExport(params) {
  return _fetch.default.export("/Admin/Withdrawal/withdrawalListExport", params, "提现记录");
} // 花呗财务账单列表


function financeHuaBeiList(params) {
  return _fetch.default.post("/Admin/Finance/financeHuaBeiList", params);
} // 花呗财务账单列表-导出


function huaBeiListExport(params) {
  return _fetch.default.export("/Admin/Finance/huaBeiListExport", params, "花呗财务账单列表");
} // 设备分期列表


function financeEquipmentList(params) {
  return _fetch.default.post("/Admin/Finance/financeEquipmentList", params);
} // 设备分期列表-导出


function equipmentListExport(params) {
  return _fetch.default.export("/Admin/Finance/equipmentListExport", params, "设备分期列表");
} // 回款列表


function financeCollectionList(params) {
  return _fetch.default.post("/Admin/Finance/financeCollectionList", params);
} // 回款列表-导出


function collectionListExport(params) {
  return _fetch.default.export("/Admin/Finance/collectionListExport", params, "回款列表");
} // 账单结算列表导出


function orderLeaseSettleExport(params) {
  return _fetch.default.export("/Admin/FinanceCheck/orderLeaseSettleExport", params, "账单结算列表");
} // 花呗订单结算列表导出


function huebeiExport(params) {
  return _fetch.default.export("/Admin/FinanceCheck/huebeiOrderSettleExport", params, "花呗订单结算列表");
} // 自动代扣开关操作


function withholdSwitch(params) {
  return _fetch.default.post("/Admin/Finance/withholdSwitch", params, true);
} // 获取修改账单为线下支付订单信息


function getOfflinePayData(oId, lId) {
  return _fetch.default.get("/Admin/Finance/getOfflinePayData", {
    order_id: oId,
    lease_id: lId
  });
} // 线下支付发送验证码


function offlinePaySendCode(oId, lId) {
  return _fetch.default.post("/Admin/Finance/offlinePaySendCode", {
    order_id: oId,
    lease_id: lId
  }, true);
} // 修改账单为线下支付


function setOfflinePay(params) {
  return _fetch.default.post("/Admin/Finance/setOfflinePay", params, true);
} // 获取订单操作记录


function getOrderLog(params) {
  return _fetch.default.get("/Admin/Finance/getOrderLog", params);
}
/**
 * 扣费管理
 */
// 获取扣费列表


function getRateList(params) {
  return _fetch.default.get("/Admin/RateConfig/get", params);
} // 增加/编辑扣费列表


function editRate(params) {
  return _fetch.default.post("/Admin/RateConfig/add", params);
} // 增加/编辑扣费列表


function getAdminFlowList(params) {
  return _fetch.default.get("/Admin/Finance/getAdminFlowList", params);
} // 增加/编辑扣费列表


function getAdminFlowSearchList(params) {
  return _fetch.default.get("/Admin/Finance/getAdminFlowSearchList", params);
} // 获取扣款信息


function getRateShop(params) {
  return _fetch.default.get("/Admin/Finance/getRateShop", params);
}