"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es6.regexp.split");

require("D:\\www\\iot_vehicle\\iot_frontend\\iot_admin\\node_modules\\core-js\\modules\\es6.array.iterator.js");

require("D:\\www\\iot_vehicle\\iot_frontend\\iot_admin\\node_modules\\core-js\\modules\\es6.promise.js");

require("D:\\www\\iot_vehicle\\iot_frontend\\iot_admin\\node_modules\\core-js\\modules\\es6.object.assign.js");

require("D:\\www\\iot_vehicle\\iot_frontend\\iot_admin\\node_modules\\core-js\\modules\\es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _elementUi = _interopRequireDefault(require("element-ui"));

var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("normalize.css/normalize.css");

require("element-ui/lib/theme-chalk/index.css");

require("@/styles/reset.scss");

require("@/styles/common.scss");

require("@/styles/element.scss");

require("@/assets/icons");

require("@/permission");

var _jsMd = _interopRequireDefault(require("js-md5"));

var _vueMonoplastySlideVerify = _interopRequireDefault(require("vue-monoplasty-slide-verify"));

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _config = _interopRequireDefault(require("./config.js"));

var _func = _interopRequireDefault(require("./utils/func.js"));

// 引入样式
// icon
// permission control
_vue.default.use(_vueClipboard.default);

_vue.default.use(_vueMonoplastySlideVerify.default); // set ElementUI lang to EN


_vue.default.use(_elementUi.default, {
  locale: _zhCN.default
});

_vue.default.config.productionTip = false;
_vue.default.prototype.$globalObj = _config.default;

_vue.default.prototype.$getImgUrl = function () {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var imgUrl = '';

  if (url) {
    var memberId = url.split('/')[0];

    if (!memberId || typeof _config.default.imgHostList[memberId] == 'undefined') {
      memberId = _config.default.defaultMemberId;
    }

    imgUrl = _config.default.imgHostList[memberId] + url;
  }

  return imgUrl;
};

_vue.default.prototype.$md5 = _jsMd.default;

_vue.default.prototype.$hasMethod = function (val) {
  return vue.$route.meta.methods && vue.$route.meta.methods.indexOf(val) > -1;
};

_vue.default.prototype.$func = _func.default;
var vue = new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});