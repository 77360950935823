"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.function.name");

var _audit = require("@/api/audit");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

var _myDatePicker = _interopRequireDefault(require("@/components/myDatePicker"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default,
    myDatePicker: _myDatePicker.default
  },
  data: function data() {
    return {
      activeName: 'all',
      searchForm: {
        shopname: '',
        account_num: '',
        addtime: ''
      },
      tableData: [],
      total: 0,
      count: {
        status_total: 0,
        notcheck_status_total: 0,
        istcheck_status_total: 0
      }
    };
  },
  methods: {
    pass: function pass(id) {
      var _this = this;

      // 通过
      this.$confirm('您确定要通过审核吗？', '通过', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _audit.editShopUserStatus)(id).then(function (res) {
          _this.getList(true);
        });
      }).catch(function () {});
    },
    // 删除
    removeItem: function removeItem(id) {
      var _this2 = this;

      this.$confirm('您确定要删除吗?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        (0, _audit.delShopUser)(id).then(function (res) {
          _this2.getList(true);
        });
      }).catch(function () {});
    },
    handleClick: function handleClick(tab) {
      this.activeName = tab.name;
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 获取数据
    getList: function getList(flag) {
      var _this3 = this;

      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _audit.supplierCheckList)((0, _objectSpread2.default)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm), {}, {
        status: this.activeName == 'all' ? '' : this.activeName
      })).then(function (res) {
        _this3.tableData = res.data || [];
        _this3.total = res.total || 0;
        _this3.count = res.count || {};
      });
    },
    // 查询
    search: function search() {
      var pickerVal = this.$refs.myDatePicker.getVal();

      if (pickerVal !== 'error') {
        this.searchForm.addtime = pickerVal;
        this.$refs.customPage.page = 1;
        this.getList();
      }
    },
    // 重置
    reset: function reset() {
      if (this.$refs.myDatePicker) {
        this.$refs.myDatePicker.reset();
      }

      this.searchForm = {
        shopname: '',
        account_num: '',
        addtime: ''
      };
      this.getList();
    },
    handleMore: function handleMore(command, item) {
      // console.log(command, item);
      // this.selectedItem = item;
      if (command == 'edit') {
        // 编辑资料
        this.$router.push({
          name: 'auditSupplierView',
          params: {
            viewId: item.id,
            toEdit: true
          }
        });
      } else if (command == 'view') {
        // 查看资料
        this.$router.push({
          name: 'auditSupplierView',
          params: {
            viewId: item.id
          }
        });
      }
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    if (from.name === "auditSupplierView") {
      next(function (vm) {
        vm.getList();
      });
    } else {
      next();
    }
  }
};
exports.default = _default;