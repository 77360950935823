"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMemberList = getMemberList;
exports.getSupplierList = getSupplierList;
exports.getSupplierInfo = getSupplierInfo;
exports.getProvinceList = getProvinceList;
exports.editSupplier = editSupplier;
exports.getCompanyArea = getCompanyArea;
exports.getSupplierGlobalConfig = getSupplierGlobalConfig;
exports.editSupplierGlobalConfig = editSupplierGlobalConfig;
exports.getSupplierConfig = getSupplierConfig;
exports.editSupplierConfig = editSupplierConfig;
exports.getConfig = getConfig;
exports.getSupplierOnceToken = getSupplierOnceToken;
exports.getSupplierVipCard = getSupplierVipCard;
exports.saveSupplierVipCard = saveSupplierVipCard;
exports.getmaYiPlatform = getmaYiPlatform;
exports.maYiPlatformCreate = maYiPlatformCreate;
exports.getMerchantApply = getMerchantApply;
exports.saveMerchantApply = saveMerchantApply;
exports.mayiMerchantzftQuery = mayiMerchantzftQuery;
exports.editPwdForShop = editPwdForShop;
exports.changePwdForPay = changePwdForPay;
exports.addSupplierToPlatformAPI = addSupplierToPlatformAPI;
exports.getPlatformListAPI = getPlatformListAPI;
exports.updatePlatformListAPI = updatePlatformListAPI;
exports.getPlatformInfoAPI = getPlatformInfoAPI;
exports.changeOss = changeOss;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

/**
 * 用户管理-用户列表
 */
// 获取用户列表
function getMemberList(params) {
  return _fetch.default.post('/Admin/Member/memberList', params);
}
/**
 * 供应商管理-供应商列表
 */
// 获取供应商列表


function getSupplierList(params) {
  return _fetch.default.post('/Admin/Supplier/supplierList', params);
} // 查看供应商信息


function getSupplierInfo(id) {
  return _fetch.default.get('/Admin/Supplier/supplierInfoById', {
    id: id
  });
} // 获取省份列表


function getProvinceList() {
  return _fetch.default.get('/Admin/Supplier/getCompanyArea');
} // 编辑供应商信息


function editSupplier(params) {
  return _fetch.default.post('/Admin/Supplier/editSupplierById', params, true);
} // 获取区域二级列表


function getCompanyArea(id) {
  return _fetch.default.get('/Admin/Supplier/getSecondCompanyArea', {
    id: id
  });
} // 获取供应商全局参数


function getSupplierGlobalConfig() {
  return _fetch.default.get('/Admin/Supplier/getSupplierGlobalConfig');
} // 编辑供应商全局参数


function editSupplierGlobalConfig(params) {
  return _fetch.default.post('/Admin/Supplier/editSupplierGlobalConfig', params, true);
} // 获取供应商配置


function getSupplierConfig(id) {
  return _fetch.default.get('/Admin/Supplier/getSupplierConfig', {
    id: id
  });
} // 编辑供应商配置


function editSupplierConfig(params) {
  return _fetch.default.post('/Admin/Supplier/editSupplierConfig', params, true);
} // 获取供应商配置详情


function getConfig(params) {
  return _fetch.default.get('/Admin/Supplier/getConfig', params);
} // 获取供应商一次性登录token


function getSupplierOnceToken(id) {
  return _fetch.default.post('/Admin/getSupplierOnceToken', {
    id: id
  });
} // 获取供应商会员卡配置


function getSupplierVipCard(id) {
  return _fetch.default.get('/Admin/Supplier/getSupplierVipCard', {
    id: id
  });
} // 修改供应商会员卡


function saveSupplierVipCard(params) {
  return _fetch.default.post('/Admin/Supplier/saveSupplierVipCard', params, true);
} // 获取供应商蚂蚁平台入驻信息


function getmaYiPlatform(params) {
  return _fetch.default.get('/Admin/Supplier/getmaYiPlatform', params);
} // 蚂蚁平台方注册


function maYiPlatformCreate(params) {
  return _fetch.default.post('/Admin/Supplier/maYiPlatformCreate', params, true);
} // 获取蚂蚁商家进件信息


function getMerchantApply(params) {
  return _fetch.default.get('/Admin/Supplier/getMerchantApply', params);
} //  商家进件


function saveMerchantApply(params) {
  return _fetch.default.post('/Admin/Supplier/saveMerchantApply', params, true);
} // 蚂蚁进件结果查询


function mayiMerchantzftQuery(params) {
  return _fetch.default.get('/Admin/Supplier/mayiMerchantzftQuery', params, true);
} // 蚂蚁进件结果查询


function editPwdForShop(params) {
  return _fetch.default.post('/Admin/Auth/editPwdForShop', params, true);
} // 总后台修改供应商密码


function changePwdForPay(params) {
  return _fetch.default.post('/Admin/Auth/changePwdForPay', params, true);
} // 添加


function addSupplierToPlatformAPI(params) {
  return _fetch.default.post('/Admin/Supplier/addSupplierToPlatform', params, true);
} // 获取平台列表


function getPlatformListAPI(params) {
  return _fetch.default.post('/Admin/Supplier/getPlatformList', params);
} // 更新平台信息


function updatePlatformListAPI(params) {
  return _fetch.default.post('/Admin/Supplier/updatePlatformList', params, true);
} // 获取平台信息


function getPlatformInfoAPI(params) {
  return _fetch.default.post('/Admin/Supplier/getPlatformInfo', params);
} // oss迁移


function changeOss(params) {
  return _fetch.default.post('/Admin/Supplier/changeOss', params, true);
}