"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.msmUseList = msmUseList;
exports.getSmsTemplate = getSmsTemplate;
exports.riskUseList = riskUseList;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// 短信使用列表
function msmUseList(params) {
  return _fetch.default.post("/Admin/Data/msmUseList", params);
} // 获取短信发送场景


function getSmsTemplate() {
  return _fetch.default.get("/Admin/Data/getSmsTemplate");
} // 风控使用列表


function riskUseList(params) {
  return _fetch.default.post("/Admin/Data/riskUseList", params);
}