"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.logout = logout;
exports.getAuthRule = getAuthRule;
exports.sendMobileCode = sendMobileCode;
exports.editPwd = editPwd;
exports.forgetPwd = forgetPwd;
exports.getImgHostList = getImgHostList;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

function login(params) {
  return _fetch.default.post("/Admin/Login", params);
}

function logout() {
  return _fetch.default.get("/Admin/LoginOut");
} // 获取用户菜单权限


function getAuthRule() {
  return _fetch.default.post("/Admin/Auth/getAuthRule");
} // 获取验证码


function sendMobileCode(mobile) {
  return _fetch.default.post("/Admin/Verify/sendCode", {
    mobile: mobile
  }, true);
} // 编辑密码


function editPwd(params) {
  return _fetch.default.post("/Admin/Auth/editPwd", params);
} // 忘记密码


function forgetPwd(params) {
  return _fetch.default.post("/Admin/Login/forgetPwd", params);
} // 获取供应商 oss imgHost 列表


function getImgHostList(params) {
  return _fetch.default.post('/Admin/getImgHostList', params);
}