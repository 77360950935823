"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/toConsumableArray"));

var _goodsLink = _interopRequireDefault(require("../../recommend/compoments/goodsLink"));

var _goodsDialog = _interopRequireDefault(require("@/components/goodsDialog.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    goodsLink: _goodsLink.default,
    goodsDialog: _goodsDialog.default
  },
  props: ['maxNum', 'goodsList'],
  data: function data() {
    return {};
  },
  mounted: function mounted() {// console.log(this.goodsList)
  },
  methods: {
    // 升序
    upSort: function upSort(index) {
      if (index === 0) {
        return;
      }

      var list = (0, _toConsumableArray2.default)(this.goodsList);
      var item = list[index];
      list[index] = list[index - 1];
      list[index - 1] = item;
      this.$emit('setGoods', list);
    },
    // 降序
    downSort: function downSort(index) {
      if (index === this.goodsList.length - 1) {
        return;
      }

      var list = (0, _toConsumableArray2.default)(this.goodsList);
      var item = list[index];
      list[index] = list[index + 1];
      list[index + 1] = item;
      this.$emit('setGoods', list);
    },
    // 删除商品
    deleteGoods: function deleteGoods(index) {
      var list = (0, _toConsumableArray2.default)(this.goodsList);
      list.splice(index, 1);
      this.$emit('setGoods', list);
    },
    // 设置选中
    setSelect: function setSelect(goodsList) {
      this.$emit('setGoods', goodsList);
    },
    // 添加商品
    openSelect: function openSelect() {
      this.$refs.goodsDialog.dialogVisible = true;
      this.$refs.goodsDialog.selectItems = (0, _toConsumableArray2.default)(this.goodsList);
    }
  }
};
exports.default = _default;