"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSuggestType = getSuggestType;
exports.complaintList = complaintList;
exports.lookDetail = lookDetail;
exports.intervene = intervene;
exports.remarksRecord = remarksRecord;
exports.addRemarks = addRemarks;

var _fetch = _interopRequireDefault(require("@/utils/fetch"));

// 获取投诉类型
function getSuggestType(params) {
  return _fetch.default.get("/Admin/Complaint/getSuggestType", params);
} // 投诉列表


function complaintList(params) {
  return _fetch.default.post("/Admin/Complaint/complaintList", params);
} // 查看


function lookDetail(id) {
  return _fetch.default.get("/Admin/Complaint/look", {
    id: id
  });
} // 介入


function intervene(params) {
  return _fetch.default.post("/Admin/Complaint/intervene", params);
} // 客服备注记录


function remarksRecord(id) {
  return _fetch.default.get("/Admin/Complaint/remarksRecord", {
    id: id
  });
} // 添加客服备注


function addRemarks(params) {
  return _fetch.default.post("/Admin/Complaint/addRemarks", params);
}