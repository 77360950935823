"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _finance = require("@/api/finance");

var _customPage = _interopRequireDefault(require("@/components/customPage"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    customPage: _customPage.default
  },
  data: function data() {
    return {
      searchForm: {
        member_id: "",
        trans_type: "",
        status: ""
      },
      amount: 0,
      service_fee: 0,
      total: 0,
      tableData: [],
      editItem: {},
      riskList: [{
        id: '1',
        name: '正常'
      }, {
        id: '2',
        name: '已退款'
      }],
      memberList: [],
      transTypeList: [{
        id: '1',
        name: '租金'
      }, {
        id: '2',
        name: '增值服务'
      }]
    };
  },
  mounted: function mounted() {
    this.getFromSearch();
    this.getList();
  },
  methods: {
    getList: function getList(flag) {
      var _this = this;

      // flag为true, 则不刷新表格
      var _this$$refs$customPag = this.$refs.customPage,
          page = _this$$refs$customPag.page,
          pageSize = _this$$refs$customPag.pageSize;

      if (!flag) {
        this.tableData = [];
      }

      (0, _finance.getAdminFlowList)((0, _objectSpread2.default)({
        page: page,
        pageSize: pageSize
      }, this.searchForm)).then(function (res) {
        _this.tableData = res.data || [];
        _this.total = res.total; // this.service_fee = res.service_fee;

        _this.amount = res.amount; // console.log(this.tableData.length)
      });
    },
    // 查询
    search: function search() {
      this.$refs.customPage.page = 1;
      this.getList();
    },
    // 重置
    reset: function reset() {
      var searchForm = this.$options.data().searchForm;
      this.searchForm = (0, _objectSpread2.default)({}, searchForm);
      this.getList();
    },
    getFromSearch: function getFromSearch() {
      var _this2 = this;

      (0, _finance.getAdminFlowSearchList)().then(function (res) {
        _this2.memberList = res.memberList; // this.transTypeList.push(...res.transTypeList) ;
      });
    }
  }
};
exports.default = _default;