"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _previewImg = _interopRequireDefault(require("@/components/previewImg"));

var _cropUpload = _interopRequireDefault(require("@/components/cropUpload"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    previewImg: _previewImg.default,
    cropUpload: _cropUpload.default
  },
  props: {
    dialogTitle: {
      type: String
    },
    handleDialog: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      imagesList: [],
      image: '',
      rules: {
        title: [{
          required: true,
          message: '请输入订单来源名称',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择状态',
          trigger: 'change'
        }]
      }
    };
  },
  watch: {
    handleDialog: function handleDialog(val) {
      if (val) {
        this.imagesList = this.formData.images ? this.formData.images : [];
        this.chkImagesList();
      }
    }
  },
  methods: {
    chkImagesList: function chkImagesList() {
      this.imagesList = this.imagesList.filter(function (item) {
        return item;
      });

      if (this.imagesList.length < 5) {
        this.imagesList.push('');
      }
    },
    closeDialog: function closeDialog(param) {
      this.$emit("closeDialog", param);
    },
    setVal: function setVal(index, value) {
      this.imagesList[index] = value;
      this.chkImagesList();
      console.log('setVal', value);
      console.log('this.imagesList', this.imagesList);
    },
    submitForm: function submitForm(formName) {
      var _this = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.imagesList = _this.imagesList.filter(function (item) {
            return item;
          });

          _this.$emit("submitForm", _this.imagesList);
        } else {
          return false;
        }
      });
    }
  }
};
exports.default = _default2;