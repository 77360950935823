"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));

require("@riophae/vue-treeselect/dist/vue-treeselect.css");

var _system = require("@/api/system");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["pItem", "editItem", "menuList"],
  components: {
    Treeselect: _vueTreeselect.default
  },
  data: function data() {
    return {
      dialogVisible: false,
      form: {
        pid: null,
        title: "",
        controller: "",
        action: "",
        path: "",
        status: 1,
        type: 1,
        sort_num: 0
      },
      rules: {
        title: [{
          required: true,
          message: '菜单名称不能为空',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: '请选择是否启用',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择所属平台',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    dialogTitle: function dialogTitle() {
      return this.editItem ? "编辑菜单" : "添加子菜单";
    }
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$refs['form'].validate(function (valid) {
        if (valid) {
          var params = (0, _objectSpread2.default)({}, _this.form);
          params.pid = params.pid || 0;

          if (_this.editItem) {
            (0, _system.editMenu)(params).then(function (res) {
              _this.dialogVisible = false;

              _this.$emit("getList");
            });
          } else {
            (0, _system.addMenu)(params).then(function (res) {
              _this.dialogVisible = false;

              _this.$emit("getList");
            });
          }
        } else {
          _this.$message({
            message: "请检查表单错误",
            type: "error"
          });

          return false;
        }
      });
    }
  },
  watch: {
    dialogVisible: function dialogVisible(val) {
      var _this2 = this;

      if (val) {
        this.$nextTick(function () {
          _this2.$refs['form'].resetFields();

          if (_this2.editItem) {
            // 编辑
            for (var key in _this2.form) {
              if (key === "pid") {
                _this2.form.pid = _this2.editItem.pid || null;
              } else {
                _this2.form[key] = _this2.editItem[key];
              }
            }

            _this2.form.id = _this2.editItem.id;
          } else {
            if (_this2.pItem) {
              _this2.form.pid = _this2.pItem.id;
              _this2.form.type = _this2.pItem.type;
            }
          }
        });
      } else {
        this.form = {
          pid: null,
          title: "",
          controller: "",
          action: "",
          path: "",
          status: 1,
          type: 1,
          sort_num: 0
        };
      }
    }
  }
};
exports.default = _default;