"use strict";

var _interopRequireDefault = require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es6.regexp.split");

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:/www/iot_vehicle/iot_frontend/iot_admin/node_modules/@babel/runtime/helpers/createForOfIteratorHelper"));

var _vuex = require("vuex");

var _bus = _interopRequireDefault(require("./bus"));

var _auth = require("@/utils/auth");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      activeRoutes: [],
      screenWidth: "",
      activeParentNav: {}
    };
  },
  methods: {
    logout: function logout() {
      this.$store.dispatch('user/logout');
    },
    goToPage: function goToPage(routes) {
      if (routes.length > 0) {
        this.$router.push({
          name: routes[0]
        });
      }
    },
    setActive: function setActive() {
      var _iterator = (0, _createForOfIteratorHelper2.default)(this.menuList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var menu = _step.value;

          var _iterator2 = (0, _createForOfIteratorHelper2.default)(menu.routes),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var item = _step2.value;
              var parentPath = this.$route.path.split('/')[1];

              if (parentPath === item) {
                this.activeRoutes = menu.routes;
                this.activeParentNav = {
                  id: menu.id,
                  route: item
                };

                _bus.default.$emit('setType', this.activeRoutes);

                _bus.default.$emit('setTopNav', this.activeParentNav);
              }
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    }
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['menu'])), {}, {
    userName: function userName() {
      return (0, _auth.getName)();
    },
    menuList: function menuList() {
      var list = [];

      var _iterator3 = (0, _createForOfIteratorHelper2.default)(this.menu),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var item = _step3.value;
          var obj = {
            id: item.id,
            title: item.label,
            routes: []
          };
          obj.routes = item.children.map(function (c) {
            return c.path;
          });
          list.push(obj);
        } // const list = [
        //   {
        //     title: "Web端",
        //     type: "web",
        //     routes: ["webConfig", "article", "recommend"]
        //   },
        //   {
        //     title: "系统",
        //     type: "system",
        //     routes: ["account", "permission", "menu"]
        //   },
        //   // {
        //   //   title: "信息",
        //   //   type: "info",
        //   //   routes: ["message", "position"]
        //   // },
        //   {
        //     title: "用户",
        //     type: "user",
        //     routes: ["supplier"]
        //   },
        //   // {
        //   //   title: "审核",
        //   //   type: "audit",
        //   //   routes: ["audit", "audit_financial"]
        //   // },
        //   // {
        //   //   title: "积分商城",
        //   //   type: "mall",
        //   //   routes: []
        //   // },
        //   // {
        //   //   title: "财务",
        //   //   type: "financial",
        //   //   routes: ["bill"]
        //   // },
        //   // {
        //   //   title: "订单",
        //   //   type: "order",
        //   //   routes: ["order"]
        //   // },
        //   // {
        //   //   title: "店铺",
        //   //   type: "shop",
        //   //   routes: []
        //   // },
        //   // {
        //   //   title: "商品",
        //   //   type: "product",
        //   //   routes: ["product"]
        //   // },
        // ];

      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }

      return list;
    }
  }),
  mounted: function mounted() {
    var _this = this;

    this.screenWidth = document.body.clientWidth;

    window.onresize = function () {
      return function () {
        _this.screenWidth = document.body.clientWidth;
      }();
    };
  },
  created: function created() {
    var _this2 = this;

    this.$nextTick(function () {
      _this2.setActive();
    });
  },
  watch: {
    $route: function $route(val) {
      this.setActive();
    }
  }
};
exports.default = _default;